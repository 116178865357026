import React from 'react'
import ContentBlock from "../components/content-block"
import Layout from '../components/layout'

const Services = ({data}) => {
  const { markdownRemark: {
    frontmatter: { title },
    html
  } } = data;
  return (
    <Layout>
      <ContentBlock wrapper>
      <div className="expertise">
        <h1>{title}</h1>
          <div
            className="about-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <a className="button" href="/contact">Click here to get started</a>
      </div>
      </ContentBlock>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ServicesQuery {
    markdownRemark(fileAbsolutePath: {regex : "/services/"}) {
      id
      html
      frontmatter {
        path
        title
      }
    }
  }
`;

export default Services;
